import "antd/dist/antd.min.css";
import React, { useState, useEffect } from "react";
import { Routes, BrowserRouter } from "react-router-dom";

import "./App.css";
import Login from "./components/Login";
import Header from "./components/Header";
import MainTabs from "./components/MainTabs";
import { initAxios } from "./helpers/login";

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState({});

  const init = async () => {
    const auth = await initAxios();
    const { access, user } = auth;
    setUser(user);
    setIsAuthenticated(!!access);
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <div>
        <Header userData={user} />
        {isAuthenticated ? (
          <MainTabs userData={user} />
        ) : (
          <Login
            onLogin={(u) => {
              setUser(u);
              setIsAuthenticated(true);
            }}
          />
        )}
        <Routes></Routes>
      </div>
    </BrowserRouter>
  );
};

export default App;
