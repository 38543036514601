import { Button, Row, Col } from "antd";
import React, { useEffect, useState } from "react";

import { initAxios } from "../helpers/login";

function Header({ userData }) {
  const [isAccess, setisAccess] = useState("");

  const logout = () => {
    window.localStorage.clear();
    window.location.reload(false);
  };

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    const auth = await initAxios();
    const { access } = auth;
    setisAccess(access);
  };

  return (
    <div className="header bg-white">
      <img
        style={{ width: "10rem", height: "3rem", marginTop: "0.5rem" }}
        src="/images/Logo.png"
        alt="logo"
      />
      <Row className="header-right">
        <Col>
          <h3 className="username">{userData?.username}</h3>
        </Col>
        <Col>{isAccess && <Button onClick={logout}>Log out</Button>} </Col>
      </Row>
    </div>
  );
}

export default Header;
