import React from "react";
import { Tabs, Col, Row, Card } from "antd";

import MainScreen from "./MainScreen";
import SwitchScreen from "./SwitchScreen";
import DiscardedTags from "./DiscardedTags";

const { TabPane } = Tabs;

const onChange = (key) => {
  console.log(key);
};

const MainTabs = ({ userData }) => {
  return (
    <div className="site-card-border-less-wrapper">
      <br />
      <Row justify="center">
        <Col {...{ md: 12, sm: 24, lg: 12, xl: 12 }}>
          <Card bordered={false}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Tabs onChange={onChange} type="card">
                <TabPane tab="Audit" key="1">
                  <MainScreen userData={userData} />
                </TabPane>
                <TabPane tab="Send/Receive" key="2">
                  <SwitchScreen userData={userData} />
                </TabPane>
                <TabPane tab="Discarded Tags" key="3">
                  <DiscardedTags userData={userData} />
                </TabPane>
              </Tabs>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default MainTabs;
