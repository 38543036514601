import {
  Switch,
  Card,
  Empty,
  Form,
  Select,
  List,
  Divider,
  Row,
  Col,
  Button,
  Input,
  message,
} from "antd";
import moment from "moment";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { DeleteOutlined } from "@ant-design/icons";

import { statusValues } from "../utils/constants";
import useCompany from "../hooks/company";

function SwitchScreen({ userData }) {
  const {
    stateGetters: {
      companyAllData,
      uniqueAssetTypes,
      warehouseAllData,
      serials,
      assetType,
    },
    stateSetters: { setSerials, setAssetType },
    actions: {
      getCompanyData,
      getNumberOfPillars,
      removeDuplicateItems,
      getWarehouse,
      deleteItem,
    },
  } = useCompany({ userData });

  const [$forceRerenderKey, setForceRerenderKey] = useState(Math.random());
  const [selectedWarehouse, setSelectedWarehouse] = useState(null);
  const [selectedWarehouseId, setSelectedWarehouseId] = useState(null);
  const [status, setStatus] = useState(null);
  const [addedItems, setAddedItems] = React.useState({});
  const [addedSerials, setAddedSerials] = useState({});
  const [scannedPillar, setScannedPillar] = useState({});
  const [loading, setLoading] = useState(false);
  const [pillarsData, setPillarsData] = useState({});
  const [scannedPillarPercentage, setScannedPillarPercentage] = useState(0);
  const [allotSend, setAllotSend] = useState([]);
  const [ReturnSend, setReturnSend] = useState([]);
  const [allotSendDetails, setAllotSendDetails] = useState([]);
  const [reciverSendDetails, setReciverSendDetails] = useState([]);
  const [senderCurrentId, setSenderCurrentId] = useState([]);
  const [sendChecked, setSendChecked] = useState(false);
  const [recivedChecked, setRecivedChecked] = useState(false);
  const [type, setType] = useState();
  const [rerenderedKey, setRerenderedKey] = useState(Math.random());
  const [form] = Form.useForm();

  useEffect(() => {
    getWarehouse();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getWarehouse();
    getAssetsPillar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedWarehouse]);

  useEffect(() => {
    axios.get("allot-send/").then(({ data }) => setAllotSend(data));
    axios.get("return-send/").then(({ data }) => setReturnSend(data));
  }, []);

  useEffect(() => {
    axios
      .get(`allot-send-details/?id=${senderCurrentId}`)
      .then(({ data }) => setAllotSendDetails(data));
  }, [senderCurrentId]);

  useEffect(() => {
    axios
      .get(`return-send-details/?id=${senderCurrentId}`)
      .then(({ data }) => setReciverSendDetails(data));
  }, [senderCurrentId]);

  const forceRerender = () => {
    setForceRerenderKey(Math.random());
  };

  const reset = () => {
    setSerials([]);
    setAssetType({});
    setAddedItems({});
    setAddedSerials({});
    setSelectedWarehouse(null);
    setStatus(statusValues.stop);
    setForceRerenderKey(Math.random());
    setScannedPillar({});
    form.setFieldsValue({ rfId: "", warehouse: null, pillarZone: null });
  };

  const handleWarehouseChange = (e) => {
    form.setFieldsValue({ rfId: "", pillarZone: null });
    const index = (warehouseAllData || []).findIndex((item) => item.id === e);
    setSelectedWarehouse(warehouseAllData[index]);
    setStatus(statusValues.start);
    setSerials([]);
    setScannedPillar({});
    setAssetType({});
    setAddedItems({});
    setAddedSerials({});
    setForceRerenderKey(Math.random());
    setScannedPillarPercentage(0);
  };

  const onAddSerial = (value, pillarZone) => {
    var t1 = performance.now();

    const currentDecodeArr = companyAllData[value];
    form.setFieldsValue({ rfId: "" });

    if (
      currentDecodeArr &&
      (currentDecodeArr[1] || "")?.toLowerCase() !== "pillar" &&
      (currentDecodeArr[1] || "") !== "Warehouse Docks" &&
      !addedSerials[value] &&
      !addedItems[currentDecodeArr[0]]
    ) {
      setAddedSerials((prev) => ({ ...prev, [value]: true }));
      setSerials((prev) =>
        removeDuplicateItems(
          [...prev, { serial: value, start: moment().toISOString() }],
          "serial"
        )
      );
      setAddedItems((prev) => ({ ...prev, [currentDecodeArr[0]]: true }));
      setAssetType((prev) => ({
        ...prev,
        [currentDecodeArr[1]]: prev[currentDecodeArr[1]]
          ? prev[currentDecodeArr[1]] + 1
          : 1,
      }));
    } else if (pillarsData && pillarsData[pillarZone]?.includes(value)) {
      const newScannedPillars = { ...scannedPillar, [value]: true };
      setScannedPillarPercentage(
        isNaN(
          (getNumberOfPillars(newScannedPillars) /
            (pillarsData[pillarZone] || []).length) *
            100
        )
          ? 0
          : (
              (getNumberOfPillars(newScannedPillars) /
                (pillarsData[pillarZone] || []).length) *
              100
            ).toFixed(1)
      );

      setScannedPillar((prev) => ({ ...prev, [value]: true }));
    }

    forceRerender();
    var t2 = performance.now();
    console.log("Call to doSomething took " + (t2 - t1) + " milliseconds.");
    setStatus(statusValues.start);
  };

  const onSubmit = async () => {
    setLoading(true);
    axios
      .post(`/create-sendReceive/`, {
        send: sendChecked,
        receive: recivedChecked,
        alt_no: senderCurrentId,
        warehouse: selectedWarehouse?.id,
        serials,
        start_time: serials[0].start,
        end_time: serials[serials.length - 1].start,
      })
      .then((e) => {
        reset();
        message.success("Scan success");
        setLoading(false);
      })
      .catch((e) => {
        message.warn("something went wrong");
        setLoading(false);
      });
  };

  const getAssetsPillar = async () => {
    if (!selectedWarehouseId) return;
    axios
      .get(`/warehouse-pillarTags/?warehouse=${selectedWarehouseId}`)
      .then((e) => setPillarsData(e.data));
  };

  const handleKeyPress = (e) => {
    if (e.code === "Enter") {
      form.submit();
    }
  };

  React.useEffect(() => {
    getCompanyData().then().catch(console.log);

    document.addEventListener("keyup", handleKeyPress);
    return () => document.removeEventListener("keyup", handleKeyPress);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTransactionChange = (value) => {
    setSenderCurrentId(value);
    setSelectedWarehouseId(value);
  };

  const onChangeSend = (checked) => {
    setSendChecked(checked);
    setRecivedChecked(!checked);
    handleResetHandler();
  };

  const onChangeRecived = (checked) => {
    setRecivedChecked(checked);
    setSendChecked(!checked);
    handleResetHandler();
  };

  const handleTypeChange = (e) => {
    setType(e);
  };
  const handleResetHandler = () => {
    (allotSendDetails || []).map((e) => e.warehouse?.name);

    form.setFieldsValue({ senderDropdow: null, reciverDropdown: null });
    setSenderCurrentId(null);
    setAllotSendDetails([]);
    setReciverSendDetails([]);
    setRerenderedKey(Math.random());
  };

  return (
    <div>
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "30px" }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginRight: "20px",
          }}
        >
          <div style={{ display: "flex" }}>
            <p style={{ marginRight: "2.1rem" }}>Send </p>
            <Switch checked={sendChecked} onChange={onChangeSend} />
          </div>
          <div style={{ display: "flex" }}>
            <p style={{ marginRight: "1rem" }}>Receive</p>
            <Switch checked={recivedChecked} onChange={onChangeRecived} />
          </div>
          <Form key={String(rerenderedKey)}>
            {recivedChecked && (
              <Form.Item name="reciverDropdown" label="Select Type:">
                <Select
                  placeholder="select"
                  onChange={handleTypeChange}
                  autoFocus={true}
                >
                  <Select.Option value="return">
                    <p>Return</p>
                  </Select.Option>
                  <Select.Option value="relocation">
                    <p>Relocation</p>
                  </Select.Option>
                </Select>
              </Form.Item>
            )}

            <Form.Item name="senderDropdown" label="Transaction No. :">
              <Select
                placeholder="Transaction"
                onChange={handleTransactionChange}
                autoFocus={true}
              >
                {type === "return" ? (
                  (ReturnSend || []).map((v) => (
                    <Select.Option key={v?.id} value={v?.id}>
                      <strong>{v?.transaction_no}</strong>
                      <p>
                        {moment(v?.dispatch_date).utc().format("YYYY-MM-DD")}
                      </p>
                    </Select.Option>
                  ))
                ) : sendChecked ? (
                  (allotSend || []).map((v) => (
                    <Select.Option key={v?.id} value={v?.id}>
                      <strong>{v?.transaction_no}</strong>
                      <p>
                        {moment(v?.dispatch_date).utc().format("YYYY-MM-DD")}
                      </p>
                    </Select.Option>
                  ))
                ) : (
                  <Empty></Empty>
                )}
              </Select>
            </Form.Item>
          </Form>
        </div>
      </div>
      <Card
        bordered={false}
        size="small"
        title="Transaction Details"
        style={{ width: "100%" }}
      >
        {sendChecked
          ? (allotSendDetails || []).map((e, index) => (
              <React.Fragment key={index}>
                <div>
                  <strong>Driver Name</strong> : {e?.driver_name}{" "}
                </div>
                <div>
                  <strong>Dispatch Date</strong> :{" "}
                  {moment(e?.dispatch_date).format("DD/MM/YY")}
                </div>
                <div>
                  <strong>Transaction No.</strong> : {e?.transaction_no}
                </div>
                <div>
                  <strong>Vehicle Number</strong> : {e?.vehicle_number}
                </div>
                <div>
                  <strong>Sender</strong> :{" "}
                  <span> {e.send_from_warehouse?.name}</span>
                </div>
                <div>
                  <strong>Reciver</strong> :{" "}
                  <span>{e?.sales_order.owner.first_name}</span>
                  <span>{e?.sales_order?.owner.last_name}</span>
                </div>
              </React.Fragment>
            ))
          : recivedChecked
          ? (reciverSendDetails || []).map((e, index) => (
              <React.Fragment key={index}>
                <div>
                  <strong>Driver Name</strong> : {e?.driver_name}
                </div>
                <div>
                  <strong>Dispatch Date</strong> :{" "}
                  {moment(e?.dispatch_date).format("DD/MM/YY")}
                </div>
                <div>
                  <strong>Transaction No.</strong> : {e?.transaction_no}
                </div>
                <div>
                  <strong>Vehicle Number</strong> : {e?.vehicle_number}
                </div>
                <div>
                  <strong>Sender</strong> :{" "}
                  <span> {e?.receiver_client?.name}</span>
                </div>
                <div>
                  <strong>Reciver</strong> : <span>{e?.warehouse.name}</span>
                </div>
              </React.Fragment>
            ))
          : null}
      </Card>
      <div>
        <br />
        <Row style={{ display: "flex" }}>
          <Col span={24}>
            <div>
              <Form
                layout="vertical"
                form={form}
                onFinish={(data) => {
                  if (data.rfId) {
                    onAddSerial(data.rfId, data.pillarZone);
                  }
                }}
              >
                <Form.Item name="warehouse" label="Select Warehouse :">
                  <Select
                    disabled={true}
                    placeholder={(allotSendDetails || []).map(
                      (e) => e.send_from_warehouse?.name
                    )}
                    onChange={handleWarehouseChange}
                    autoFocus={true}
                  >
                    {(allotSendDetails || []).map((v) => (
                      <Select.Option key={v.id} value={v.id}>
                        {v.send_from_warehouse.id}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item name="rfId" label="">
                  <Input placeholder="Enter RFID" maxLength={24} />
                </Form.Item>
                <Form.Item>
                  <Button block htmlType="submit" type="primary">
                    Add
                  </Button>
                </Form.Item>
              </Form>
              <br />
              {Object.keys(assetType).length > 0 && (
                <Row className="bg-light px-2 py-1">
                  <Col span={8}>
                    <b>Asset Type</b>
                  </Col>
                  <Col span={8}>
                    <b>Count</b>
                  </Col>
                  <Col span={8}>
                    <b>Scanned Percentage</b>
                  </Col>
                </Row>
              )}
              <div
                className="border"
                key={String($forceRerenderKey) + "countTable"}
              >
                {Object.keys(assetType).map((key) => (
                  <Row key={key} className="px-2">
                    <Col span={8}>{key}</Col>
                    <Col span={8}>{assetType[key]}</Col>
                    <Col>
                      {((assetType[key] / uniqueAssetTypes[key]) * 100).toFixed(
                        1
                      )}
                      %
                    </Col>
                  </Row>
                ))}
              </div>
              <div>
                {serials.length ? (
                  <div key={String($forceRerenderKey)}>
                    <Divider orientation="left">
                      Total Serial Count (Count: {serials?.length})
                    </Divider>
                    {
                      <div className="scroll-view">
                        <List
                          size="small"
                          itemLayout="horizontal"
                          dataSource={serials}
                          renderItem={(item) => (
                            <List.Item
                              actions={[
                                <DeleteOutlined
                                  onClick={() => {
                                    deleteItem(item.$key, item.serial);
                                  }}
                                />,
                              ]}
                            >
                              <List.Item.Meta
                                title={
                                  <p className="serials-title">
                                    {(companyAllData[item.serial] &&
                                      companyAllData[item.serial][0]) ||
                                      item.serial}
                                  </p>
                                }
                              />
                            </List.Item>
                          )}
                        />
                      </div>
                    }
                  </div>
                ) : (
                  <Empty
                    imageStyle={{ height: 300 }}
                    description={<span>Please Select Warehouse and Start</span>}
                  />
                )}
              </div>
              <br />

              <div className="">
                {status !== statusValues.start && (
                  <Button
                    className="mr-2"
                    type="primary"
                    onClick={() => setStatus(statusValues.start)}
                  >
                    Start
                  </Button>
                )}
                {status === statusValues.start && (
                  <Button
                    className="mr-2"
                    type="danger"
                    onClick={() => setStatus(statusValues.stop)}
                  >
                    Stop
                  </Button>
                )}
                <Button
                  className="mx-2"
                  disabled={status !== statusValues.start}
                  onClick={reset}
                  type="warning"
                >
                  Reset
                </Button>
                <Button
                  key={"submit" + String(scannedPillarPercentage)}
                  loading={loading}
                  className="ml-2"
                  disabled={status !== statusValues.start || loading}
                  onClick={onSubmit}
                >
                  Submit
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default SwitchScreen;
