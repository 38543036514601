import axios from "axios";
import React from "react";
import { useRecoilState } from "recoil";

import companyData from "../atoms/company";
import warehouseData from "../atoms/warehouse";

const useCompany = ({ userData }) => {
  const [companyAllData, setCompanyAllData] = useRecoilState(companyData);
  const [warehouseAllData, setWarehouseAllData] = useRecoilState(warehouseData);

  const [serials, setSerials] = React.useState([]);
  const [TypesToMap, setTypesToMap] = React.useState({});
  const [assetType, setAssetType] = React.useState({});
  const [uniqueAssetTypes, setUniqueAssetTypes] = React.useState({});

  const getUniqueItemsLength = (decodeValues) => {
    const addedItems = Object.values(decodeValues).reduce((acc, curr) => ({
      ...acc,
      [curr[1]]: addedItems[curr[1]] ? addedItems[curr[1]] + 1 : 1,
    }));

    setUniqueAssetTypes(addedItems);
    return addedItems.length;
  };

  const getWarehouse = async () => {
    if (!userData.company_id) return;

    const { data } = await axios.get(
      `/company-warehouse/?id=${userData.company_id}`
    );
    setWarehouseAllData(data);
    return data;
  };

  const getCompanyData = async () => {
    if (Object.keys(companyAllData).length > 0) {
      return companyAllData;
    }

    let uniqueType = {};
    const { data } = await axios.get(
      `grnserial-conversion/?company=${userData?.company_id}`
    );

    Object.keys(data).map(
      (key) => (uniqueType = { ...TypesToMap, [data[key][1]]: true }),
      setTypesToMap(uniqueType)
    );

    setCompanyAllData(data);
    getUniqueItemsLength(data);

    return data;
  };

  const getNumberOfPillars = (p) => {
    let num = 0;
    Object.keys(p || {}).forEach((key) => {
      if (p[key]) {
        num = num + 1;
      }
    });
    return num;
  };

  const removeDuplicateItems = (arr, key) => {
    const newArr = new Map(arr.map((item) => [item[key], item])).values();
    return [...newArr];
  };

  const deleteItem = (key, serial) => {
    const selectedDecodeValue = companyAllData[serial];
    if (selectedDecodeValue && assetType[selectedDecodeValue[1]]) {
      setAssetType((prev) => ({
        ...prev,
        ...(prev[selectedDecodeValue[1]] && {
          [selectedDecodeValue[1]]: prev[selectedDecodeValue[1]] - 1,
        }),
      }));
    }
    setSerials((prev) => prev.filter((item) => item.$key !== key));
  };

  return {
    stateGetters: {
      companyAllData,
      TypesToMap,
      uniqueAssetTypes,
      warehouseAllData,
      serials,
      assetType,
    },

    stateSetters: {
      setCompanyAllData,
      setTypesToMap,
      setUniqueAssetTypes,
      setWarehouseAllData,
      setSerials,
      setAssetType,
    },

    actions: {
      getCompanyData,
      getUniqueItemsLength,
      getWarehouse,
      getNumberOfPillars,
      removeDuplicateItems,
      deleteItem,
    },
  };
};

export default useCompany;
