import {
  List,
  Divider,
  Button,
  Row,
  Col,
  Empty,
  Form,
  Select,
  Input,
  message,
  Popover,
} from "antd";
import axios from "axios";
import moment from "moment";
import React, { useState } from "react";
import { DeleteOutlined } from "@ant-design/icons";

import { statusValues } from "../utils/constants";
import useCompany from "../hooks/company";

const MainScreen = ({ userData }) => {
  const {
    stateGetters: {
      companyAllData,
      uniqueAssetTypes,
      warehouseAllData,
      serials,
      assetType,
    },
    stateSetters: { setSerials, setAssetType },
    actions: {
      getCompanyData,
      getNumberOfPillars,
      removeDuplicateItems,
      getWarehouse,
      deleteItem,
    },
  } = useCompany({ userData });

  const [$forceRerenderKey, setForceRerenderKey] = useState(Math.random());
  const [selectedWarehouse, setSelectedWarehouse] = useState(null);
  const [status, setStatus] = useState(null);
  const [addedItems, setAddedItems] = React.useState({});
  const [addedSerials, setAddedSerials] = useState({});
  const [scannedPillar, setScannedPillar] = useState({});
  const [loading, setLoading] = useState(false);

  const [pillarsData, setPillarsData] = useState({});
  const [scannedPillarPercentage, setScannedPillarPercentage] = useState(0);

  const rfidInputFocus = React.useRef(null);

  React.useEffect(() => {
    rfidInputFocus.current.focus();
  }, [warehouseAllData, serials, scannedPillar]);

  const [form] = Form.useForm();

  const forceRerender = () => {
    setForceRerenderKey(Math.random());
  };
  console.log(pillarsData,"pillarsData");

  const reset = () => {
    setSerials([]);
    setAssetType({});
    setAddedItems({});
    setAddedSerials({});
    setSelectedWarehouse(null);
    setStatus(statusValues.stop);
    setForceRerenderKey(Math.random());
    setScannedPillar({});
    form.setFieldsValue({ rfId: "", warehouse: null, pillarZone: null });
  };

  const handleWarehouseChange = (e) => {
    form.setFieldsValue({ rfId: "", pillarZone: null });
    const index = (warehouseAllData || []).findIndex((item) => item.id === e);
    setSelectedWarehouse(warehouseAllData[index]);
    setStatus(statusValues.start);
    setSerials([]);

    setScannedPillar({});
    setAssetType({});
    setAddedItems({});
    setAddedSerials({});
    setForceRerenderKey(Math.random());
    setScannedPillarPercentage(0);
  };

  const handleAssetsPillar = (value) => {
    console.log(value,"valllllll");
    setScannedPillarPercentage(0);
    setScannedPillar({});
  };

  React.useEffect(() => {
    getWarehouse();
    getAssetsPillar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedWarehouse]);

  const onAddSerial = (value, pillarZone) => {
    var t1 = performance.now();

    const currentDecodeArr = companyAllData[value];
    form.setFieldsValue({ rfId: "" });

    if (
      currentDecodeArr &&
      // (currentDecodeArr[1] || "")?.toLowerCase() !== "pillar" &&
      // (currentDecodeArr[1] || "") !== "Warehouse Docks" &&
      !addedSerials[value] &&
      !addedItems[currentDecodeArr[0]]
    ) {
      setAddedSerials((prev) => ({ ...prev, [value]: true }));
      setSerials((prev) =>
        removeDuplicateItems(
          [
            ...prev,
            {
              serial: value,
              start: moment().toISOString(),
            },
          ],
          "serial"
        )
      );
      setAddedItems((prev) => ({ ...prev, [currentDecodeArr[0]]: true }));
      setAssetType((prev) => ({
        ...prev,
        [currentDecodeArr[1]]: prev[currentDecodeArr[1]]
          ? prev[currentDecodeArr[1]] + 1
          : 1,
      }));
    } else if (pillarsData && pillarsData[pillarZone]?.includes(value)) {
      // const newScannedPillars = { ...scannedPillar, [value]: true };
      // setScannedPillarPercentage(
      //   isNaN(
      //     (getNumberOfPillars(newScannedPillars) /
      //       (pillarsData[pillarZone] || []).length) *
      //       100
      //   )
      //     ? 0
      //     : (
      //         (getNumberOfPillars(newScannedPillars) /
      //           (pillarsData[pillarZone] || []).length) *
      //         100
      //       ).toFixed(1)
      // );

      setScannedPillar((prev) => ({ ...prev, [value]: true }));
    }

    forceRerender();
    var t2 = performance.now();
    console.log("Call to doSomething took " + (t2 - t1) + " milliseconds.");
  };

  const onSubmit = async () => {
    setLoading(true);
    axios
      .post(`/create-rfidinv/`, {
        warehouse: selectedWarehouse?.id,
        serials,
        zone: form.getFieldValue("pillarZone"),
        start_time: serials[0].start,
        end_time: serials[serials.length - 1].start,
      })
      .then((e) => {
        reset();
        message.success("Scan success");
        setLoading(false);
      })
      .catch((e) => {
        message.warn("something went wrong");
        setLoading(false);
      });
  };

  const getAssetsPillar = async () => {
    if (!selectedWarehouse || !selectedWarehouse.id) return;
    axios
      .get(`/warehouse-pillarTags/?warehouse=${selectedWarehouse?.id}`)
      .then((e) => setPillarsData(e.data));
  };

  const handleKeyPress = (e) => {
    if (e.code === "Enter") {
      form.submit();
    }
  };

  React.useEffect(() => {
    getCompanyData().then().catch(console.log);

    document.addEventListener("keyup", handleKeyPress);
    return () => document.removeEventListener("keyup", handleKeyPress);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <br />
      <Row style={{ display: "flex" }}>
        <Col span={24}>
          <div>
            <Form
              layout="vertical"
              form={form}
              onFinish={(data) => {
                if (data.rfId) {
                  onAddSerial(data.rfId, data.pillarZone);
                }
              }}
            >
              <Form.Item name="warehouse" label="Select Warehouse :">
                <Select
                  placeholder="Select Warehouse"
                  onChange={handleWarehouseChange}
                  autoFocus={true}
                >
                  {(warehouseAllData || []).map((v) => (
                    <Select.Option key={v.id} value={v.id}>
                      {v.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item name="pillarZone" label="Zone Pillar :">
                <Select
                  autoFocus={true}
                  disabled={!selectedWarehouse || status !== statusValues.start}
                  placeholder="Set Pillar Zone"
                  onChange={handleAssetsPillar}
                >
                  {(Object.entries(pillarsData) || []).map((v, i) => (
                    <Select.Option key={v[0]} value={v[0]}>
                      {v[0]}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name="rfId" label="">
                <Input
                  disabled={!selectedWarehouse || status !== statusValues.start}
                  placeholder="Enter RFID"
                  maxLength={24}
                  ref={rfidInputFocus}
                  autocomplete="off"
                />
              </Form.Item>
              <Form.Item>
                <Button
                  disabled={status !== statusValues.start}
                  block
                  htmlType="submit"
                  type="primary"
                >
                  Add
                </Button>
              </Form.Item>
            </Form>
            <br />

            {/* {(pillarsData[form.getFieldValue("pillarZone")] || []).length >
              0 && (
              <>
                <Row className="bg-light px-2 py-1">
                  <Col span={20}>
                    <b>Pillar</b>
                  </Col>
                  <Col span={4}>
                    <b>Percantage</b>
                  </Col>
                </Row>

                <div className="border" key={String($forceRerenderKey) + "epc"}>
                  <Row className="pillar-scroll-view px-2 py-2">
                    <Col span={20}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexWrap: "wrap",
                        }}
                      >
                        {(
                          pillarsData[form.getFieldValue("pillarZone")] || []
                        ).map((item, i) => (
                          <div>
                            {scannedPillar[item] ? (
                              <Popover
                                key={"popover" + i}
                                content={
                                  (companyAllData[item] &&
                                    companyAllData[item][0]) ||
                                  item
                                }
                                trigger="click"
                              >
                                <img
                                  className="p-1"
                                  height={"40rem"}
                                  color="white"
                                  src="/images/PillarGreen.jpeg"
                                  alt="pillars"
                                />
                              </Popover>
                            ) : (
                              <Popover
                                key={"popover" + i}
                                content={
                                  (companyAllData[item] &&
                                    companyAllData[item][0]) ||
                                  item
                                }
                                trigger="click"
                              >
                                <img
                                  className="p-1"
                                  height={"40rem"}
                                  color="white"
                                  src="/images/PillarRed.jpeg"
                                  alt="pillars"
                                />
                              </Popover>
                            )}
                          </div>
                        ))}
                      </div>
                    </Col>
                    <Col style={{ paddingLeft: "1rem" }} span={4}>
                      {scannedPillarPercentage}%
                    </Col>
                  </Row>
                </div>
                <br />
              </>
            )} */}

            {Object.keys(assetType).length > 0 && (
              <Row className="bg-light px-2 py-1">
                <Col span={12}>
                  <b>Asset Type</b>
                </Col>
                <Col span={12}>
                  <b>Count</b>
                </Col>
                {/* <Col span={8}>
                  <b>Scanned Percentage</b>
                </Col> */}
              </Row>
            )}
            <div
              className="border"
              key={String($forceRerenderKey) + "countTable"}
            >
              {Object.keys(assetType).map((key) => (
                <Row key={key} className="px-2">
                  <Col span={12}>{key}</Col>
                  <Col span={12}>{assetType[key]}</Col>
                  {/* <Col>
                    {((assetType[key] / uniqueAssetTypes[key]) * 100).toFixed(
                      1
                    )}
                    %
                  </Col> */}
                </Row>
              ))}
            </div>
            <div>
              {serials.length ? (
                <div key={String($forceRerenderKey)}>
                  <Divider orientation="left">
                    Total Serial Count (Count: {serials?.length})
                  </Divider>
                  {
                    <div className="scroll-view">
                      <List
                        size="small"
                        itemLayout="horizontal"
                        dataSource={serials}
                        renderItem={(item) => (
                          <List.Item
                            actions={[
                              <DeleteOutlined
                                disabled={status !== status.start}
                                onClick={() => {
                                  deleteItem(item.$key, item.serial);
                                }}
                              />,
                            ]}
                          >
                            <List.Item.Meta
                              title={
                                <p className="serials-title">
                                  {(companyAllData[item.serial] &&
                                    companyAllData[item.serial][0]) ||
                                    item.serial}
                                </p>
                              }
                            />
                          </List.Item>
                        )}
                      />
                    </div>
                  }
                </div>
              ) : (
                <Empty
                  imageStyle={{ height: 300 }}
                  description={<span>Please Select Warehouse and Start</span>}
                />
              )}
            </div>
            <br />

            <div className="">
              {/* {status !== statusValues.start && (
                <Button
                  className="mr-2"
                  type="primary"
                  onClick={() => {
                    setStatus(statusValues.start);
                  }}
                >
                  Start
                </Button>
              )}
              {status === statusValues.start && (
                <Button
                  className="mr-2"
                  type="danger"
                  onClick={() => {
                    setStatus(statusValues.stop);
                  }}
                >
                  Stop
                </Button>
              )} */}
              {/* <Button
                className="mx-2"
                disabled={status !== statusValues.start}
                onClick={reset}
                type="warning"
              >
                Reset
              </Button> */}
              <Button
                key={"submit" + String(scannedPillarPercentage)}
                loading={loading}
                className="ml-2"
                disabled={status !== statusValues.start || loading}
                onClick={onSubmit}
              >
                Submit
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default MainScreen;
